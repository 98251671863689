import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useCellImagesImagesResetEdited from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-images/use-cell-images-images-reset-edited';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setContent,
    setContentCellImagesSelected,
  } = useEditorStatesSetters();

  const resetImageEdited = useCellImagesImagesResetEdited();

  const removeImage = (event: KeyboardEvent) => {
    const imagesSelected = docState.contentCellImagesSelected.getSelected();
    const imagesKeys = Object.keys(imagesSelected);
    if ( ! imagesKeys.length ) {
      return false;
    }

    const imagesAddrs = imagesKeys.map(imageKey => ContentTools.imageKeyToAddr(imageKey));
    imagesAddrs.forEach((imageAddr) => docState.content.cellImages_removeImage(imageAddr));

    docState.saveUndo();
    setContent();

    docState.contentCellImagesSelected.reset();
    setContentCellImagesSelected();

    resetImageEdited(imagesAddrs);
    return true;
  }


  return {
    removeImage,
  }
}

export default useFunctions;