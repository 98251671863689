import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';
import useKeyBindingsAdd from 'lego/components/key-bindings/hooks/use-key-bindings-add';


const useKeyBindings = () => {
  const {
    addBindings,
    removeBindings
  } = useKeyBindingsAdd([
    // TODO: this should be really done so it captures all key strokes
    // except those which are for main window top menu (alt+s, alt+shift+x, or event alt+r) 
    
    // This is stop barrier. 
    // Basically i capture key strokes which should not
    // be passed to the underlying keybinds components
    {
      check: (event: any) => {
        return [
          'Delete',
          'Backspace',
          'Escape',
        ].includes(event.key);
      },
      callback: (event: any) => {
        console.log("EditorMarker key stroke barrier")
        return true;
      },
      priority: KeyBindingPriority.EDITOR_MARKER
    },
  ]);

  return {
    addBindings,
    removeBindings,
  }
}

export default useKeyBindings;
