import React           from 'react';
import { useRef }      from 'react';
import { useEffect }   from 'react';
import { useState }    from 'react';
import { useRecoilValue }    from 'recoil';
import { useSetRecoilState } from 'recoil';

import useKeyBindingBarrier from 'lego-hooks/use-key-binding-barrier';

import { settings }        from 'app/configs';
import { validate }        from 'app/arch/editor-instruction/validators/validate';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDocState }     from 'app/ui/contexts/document';
import { UIState_MetaDataState } from 'app/ui/states/editor-instruction';
import { useIsPrintout }   from 'app/ui/components/editor-instruction/use-is-printout';
import { useDebouncedCallback } from 'use-debounce';

import { Content }    from './styles';
import { TitleText }  from './styles';
import { TitleError } from './styles';
import { DescriptionText } from './styles';
import { TitleErrorPositioner } from './styles';


const TEXT_DEBOUNCE_DELAY = settings.editorText.updateDebounceDelay;


interface Props {}


export const DocumentTitleComponent: React.FC<Props> = (props: Props) => {
  // This double split in DocumentTitle component is needed as 
  // we are using debounce routine. So if it was in one component
  // which gets rerender after state commit debounce function would
  // get messed up - as it would not have correct prev its ref. 
  //
  // Not sure if that is true ;)
  const document = useDocState();
  const titleGlobal       = useRecoilValue(UIState_MetaDataState.title);
  const descriptionGlobal = useRecoilValue(UIState_MetaDataState.description);

  const setDocInfoState = useSetRecoilState(UIState_MetaDataState.state);

  const commitGlobalState = useDebouncedCallback(() => {
    document.saveUndo();
    setDocInfoState(document.metaData.state);
  }, TEXT_DEBOUNCE_DELAY);

  const handleTitleChange = (title: string) => {
    document.metaData.setTitle(title);
    commitGlobalState();
  }

  const handleDescriptionChange = (description: string) => {
    document.metaData.setDescription(description);
    commitGlobalState();
  }
  
  return (
    <DocumentTitleComponent__ 
      title={titleGlobal}
      description={descriptionGlobal}
      onTitleChange={handleTitleChange}
      onDescriptionChange={handleDescriptionChange}
    />
  );
}



//
//-------------------------------
//

interface Props__ {
  title: string,
  description: string,
  onTitleChange: (title: string) => void;
  onDescriptionChange: (description: string) => void;
}


export const DocumentTitleComponent__: React.FC<Props__> = (props: Props__) => {
  const {
    onTitleChange,
    onDescriptionChange
  } = props;

  const t = useTranslations();
  const document = useDocState();
  const checkKeyBarrier = useKeyBindingBarrier();

  const setDocInfoState = useSetRecoilState(UIState_MetaDataState.state);
  const isPrintout = useIsPrintout();


  //---------------------
  // Title
  //
  const titlePrevRef = useRef('');

  const [titleLocal, setTitleLocal]     = useState(props.title);
  const [titleFocused, setTitleFocused] = useState(false);


  //---------------------
  // Description
  //
  const [descriptionLocal, setDescriptionLocal] = useState(props.description);
  const [descriptionFocused, setDescriptionFocused] = useState(false);


  //-------------------
  // UseEffet title
  //
  useEffect(() => {
    if (props.title === titleLocal) return;
    setTitleLocal(props.title);
  }, [props.title]);


  //-------------------------
  // UseEffet description
  //
  useEffect(() => {
    if (props.description === descriptionLocal) return;
    setDescriptionLocal(props.description);
  }, [props.description]);


  const isTitleValid = (title: string) => {
    return validate.document.title(title.trim());
  } 
  
  //-----------------
  // Title changes
  //
  const handleTitleChange = (event: any) => {
    const titleNewTmp = event.target.value;
    const titleNew    = titleNewTmp.replace(/^\n+/, '');
    setTitleLocal(titleNew);

    if ( isTitleValid(titleNew) ) {
      onTitleChange(titleNew);
    }
  };

  const handleTitleFocus = () => {
    titlePrevRef.current = titleLocal;
    setTitleFocused(true);
  }

  const handleTitleBlur = () => {
    if ( ! isTitleValid(titleLocal) ) {
      restoreLastValidTitle();
    }

    setTitleFocused(false);
  }
  
  const restoreLastValidTitle = () => {
    setTitleLocal(titlePrevRef.current);
    document.metaData.setTitle(titlePrevRef.current);
    document.saveUndo();
    setDocInfoState(document.metaData.state);
  }


  //----------------------
  // Description changes
  //

  const handleDescriptionChange = (event: any) => {
    const descriptionNewTmp = event.target.value;
    const descriptionNew = descriptionNewTmp.replace(/^\n+/, '');

    setDescriptionLocal(descriptionNew);
    onDescriptionChange(descriptionNew);
  };

  const handleDescriptionFocus = () => {
    setDescriptionFocused(true);
  }

  const handleDescriptionBlur = () => {
    setDescriptionFocused(false);
  }

  const descriptionPlaceholderText = (
    ! isPrintout && 
    ! descriptionFocused ? 
    t("document description placeholder") :
    ""
  );

  const showTitleError = ! isTitleValid(titleLocal) && titleFocused;

  return (
    <Content>
      <TitleErrorPositioner>
        <TitleError $visible={showTitleError}>
          { t("document title, error too short") }
        </TitleError>
      </TitleErrorPositioner>

      <TitleText
        data-test={'doc-header-title'}
        value={titleLocal}
        onInput={handleTitleChange}
        onFocus={handleTitleFocus}
        onBlur={handleTitleBlur}
        onKeyDown={checkKeyBarrier}
      />

      <DescriptionText
        data-test={'doc-header-description'}
        value={descriptionLocal}
        onInput={handleDescriptionChange}
        onFocus={handleDescriptionFocus}
        onBlur={handleDescriptionBlur}
        placeholder={descriptionPlaceholderText}
        onKeyDown={checkKeyBarrier}
      />

    </Content>
  );

}
