import React from 'react';
import RedirectGoUrlComponent from '../parts/redirect-go-url';


interface Props {
}


export const RedirectToIntroMovieComponent: React.FC<Props> = (props: Props) => {
  // const message = 'Film Intro';
  const url = 'https://www.youtube.com/watch?v=njxl1Wf0T3c';

  return (
    <RedirectGoUrlComponent
      // message={message}
      url={url}
    />
  );
}

