import { IconAddr }        from "./types";
import { IconDescriptor } from "./types";


type ResolverType = { 
  [name: string]: IconAddr[]
};


class IconsRepo__ {
  private static instance: IconsRepo__;

  private _icons: any[][];
  private _resolver: ResolverType;
  private _resolverKeys: string[];
  private _ready: boolean;

  private constructor() {
    this._icons = [];
    this._resolver = {};
    this._resolverKeys = [];
    this._ready = false;
  }

  get ready() { return this._ready; }
  
  async setupIcons() {
    const iconLibs = [
      () => import("react-icons/ai"),
      () => import("react-icons/bs"),
      () => import("react-icons/bi"),
      () => import("react-icons/ci"),
      () => import("react-icons/cg"),
      () => import("react-icons/di"),
      () => import("react-icons/fi"),
      () => import("react-icons/fc"),
      () => import("react-icons/fa"),
      () => import("react-icons/fa6"),
      () => import("react-icons/gi"),
      () => import("react-icons/go"),
      () => import("react-icons/gr"),
      () => import("react-icons/hi"),
      () => import("react-icons/hi2"),
      () => import("react-icons/im"),
      () => import("react-icons/lia"),
      () => import("react-icons/io"),
      () => import("react-icons/io5"),
      () => import("react-icons/lu"),
      () => import("react-icons/md"),
      () => import("react-icons/pi"),
      () => import("react-icons/rx"),
      () => import("react-icons/ri"),
      () => import("react-icons/si"),
      () => import("react-icons/sl"),
      () => import("react-icons/tb"),
      () => import("react-icons/tfi"),
      () => import("react-icons/ti"),
      () => import("react-icons/vsc"),
      () => import("react-icons/wi"),
    ];

    const IconsLibs = await Promise.all(iconLibs.map(lib => lib()));
  
    this._icons = IconsLibs.map((icons) => Object.entries(icons).map(([key, Icon]) => {
      const iconDescriptor: IconDescriptor = { key, Icon };
      return iconDescriptor;
    }));

    const resolver: ResolverType = {};

    this._icons.forEach((icons, row) => {
      icons.forEach((icon, column) => {
        if ( ! (icon.key in resolver)) {
          resolver[`${icon.key}`] = []
        }
        else {
          // console.warn(icon.key);
        }
        resolver[`${icon.key}`].push({row, column});
      })
    });

    this._resolver = resolver;
    this._resolverKeys = Object.keys(resolver);
    this._ready = true;
  }

  getIcons(iconSearchText_: string) {
    const iconSearchText = iconSearchText_.replace(/\s/g, '');

    if (iconSearchText.length < 3) {
      return [];
    }

    const matchedKeys = this._resolverKeys.filter((key) => {
      return key.toLowerCase().includes(iconSearchText.toLowerCase());
    });
    
    const matchedIconsIdxs = matchedKeys.map((key: string) => {
      return this._resolver[key];
    })

    return matchedIconsIdxs.flat();
  }

  getIcon(iconIdx: IconAddr) {
    return this._icons[iconIdx.row][iconIdx.column];
  }

  getRandom() {
    const row = Math.floor(Math.random() * this._icons.length);
    const columns = this._icons[row];
    const column = Math.floor(Math.random() * columns.length);

    return {row, column};
  }

  public static getInstance(): IconsRepo__ {
    if ( ! IconsRepo__.instance) {
      IconsRepo__.instance = new IconsRepo__();
    }
    return IconsRepo__.instance;
  }
}

const IconsRepo = IconsRepo__.getInstance();
export default IconsRepo;
