import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { PanelDraggableItemConfig } from '../../config';
import { Input } from './styles';
import { Text } from './styles';
import { MainWrapperButton } from './styles';


interface Props {
  config: PanelDraggableItemConfig;
}


export const ItemTextComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;
  const [value, setValue] = useState(config.value);
  const [editing, setEditing] = useState(false);
  
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const handleDblClick = () => {
    if (config.editable) {
      setEditing(true);
    }
  }

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();

    if (event.key === "Enter") {
      commitNewValue();
    }
    else if (event.key === "Escape") { 
      cancelEditing();
    }
  }

  const handleInputBlur = () => {
    commitNewValue();
  }

  const handleInputChange = (event: any) => {
    setValue(event.target.value);
    config.onValueChange?.(event.target.value);
  }

  const commitNewValue = () => {
    setEditing(false);
    config.onValueChangeDone?.(value);
  }

  const cancelEditing = () => {
    setEditing(false);
    setValue(config.value?? '');
  }

  const renderEditing = () => {
    return (
      <Input 
        ref={inputRef}
        value={value}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
      />
    )
  }

  const renderNotEditing = () => {
    const text = (
      config.value.trim().length > 0 ? 
      config.value.trim() : 
      <>&nbsp;</>
    );

    return (
      <MainWrapperButton
        selected={config.selected}
        onDoubleClick={handleDblClick}
      >
        <Text>
        { text }
        </Text>
      </MainWrapperButton>
    );
  }

  return (
   <>
    { editing   && renderEditing() }
    { ! editing && renderNotEditing() }
   </>
  );
}
  
