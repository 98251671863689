import React from 'react';
import { useState }   from 'react';
import InputRawComponent from '../input-raw';
import { InputRawProps } from '../input-raw';

import { HideIcon }     from './styles';
import { InputWrapper } from './styles';
import { Button }       from './styles';
import { ShowIcon }     from './styles';
import { MainWrapper }  from './styles';
import { InputTitle }   from './styles';


export interface Props extends InputRawProps {
  title?: string;
};


export const InputComponent = (props: Props) => {
  const {
    title,
    type: typeInit,
  } = props;
  
  const [type, setType] = useState(typeInit || 'text');

  const handlePasswordToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (typeInit === 'password') {
      if (type === 'password') {
        setType('text');
      }
      else {
        setType('password');
      }
    }
  }

  const renderVisibleToggle = () => {
    const Icon = type ==='password' ? ShowIcon : HideIcon;
    return (
      <Button 
        type="button"
        onClick={handlePasswordToggle}
      >
        <Icon />
      </Button>
    );
  }


  return (
    <MainWrapper>
      {
        title !== undefined &&
        <InputTitle>
        { title }
        </InputTitle>
      }

      <InputWrapper>
        <InputRawComponent
          {...props}
          type={type}
        />

        {
          typeInit === 'password' && 
          renderVisibleToggle()
        }

      </InputWrapper>
    </MainWrapper>
  )
}
