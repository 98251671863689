import styled from "styled-components";
import PanelThird from "lego/styles/panel_third";
import InputBase from "lego/styles/input";


export const TypesList = styled(PanelThird)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xsmall};
`;


export const Input = styled(InputBase)`
  // TODO
  // Othewise Input gets to big and pushs 
  // button out of the screen.
  width: 0px;
  flex-grow: 1;
`;
