import { RepoMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setContent,
    setEditorMarkerSession,
    setRepoMarkers,
    setGalleryMarkersSelected,
    setHeaderMarkers,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();

  const duplicateMarker = () => {
    const markersSelected = docState.galleryMarkersSelected.getSelected();
    const markersAddrs = Object.keys(markersSelected).map(markerKey => RepoMarkersTools.markerKeyToAddr(markerKey));

    const newMarkersAddrs = markersAddrs.map(markerAddr => {
      const newMarkerAddr = docState.repoMarkers.duplicateMarker(markerAddr);
      return newMarkerAddr;
    });

    docState.saveUndo();
    setRepoMarkers();

    docState.galleryMarkersSelected.reset();
    newMarkersAddrs.forEach(markerAddr => docState.galleryMarkersSelected.select(markerAddr));
    setGalleryMarkersSelected();

    hideMenu();
  }

  const deleteMarker = () => {
    const markersSelected = docState.galleryMarkersSelected.getSelected();
    const markersAddrs = Object.keys(markersSelected).map(markerKey => RepoMarkersTools.markerKeyToAddr(markerKey));

    markersAddrs.forEach(markerAddr => docState.docMarker_delete(markerAddr));
    docState.saveUndo();

    setContent();
    setRepoMarkers();
    setHeaderMarkers();
    setEditorMarkerSession();

    docState.galleryMarkersSelected.reset();
    setGalleryMarkersSelected();

    hideMenu();
  }

  return {
    duplicateMarker,
    deleteMarker,
  };
}


export default useFunctions;