import React from 'react';
import { useRecoilValue } from 'recoil';
import { IconContext } from 'react-icons';
import { useThrottledCallback } from 'use-debounce';

import InputComponent from 'app/ui-v2/editor-instruction/hls/input';
import { useDocState } from 'app/ui/contexts/document';
import { useIconsRepo } from 'app/ui-v2/editor-instruction/hooks/use-icons-repo';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_EditorMarkerSession } from 'app/ui-v2/editor-markers/states';

import { IconAddr } from 'tools/icon-repo/types';

import { Button } from './styles';
import { MainWrapper } from './styles';
import { InputWrapper } from './styles';
import { IconsScroller } from './styles';
import { IconsWrapper }  from './styles';
import { Panel } from './styles';
import { Input } from './styles';


const INPUT_CHANGE_THROTTLED_DELAY = 500;


interface Props {
  onSelectIcon: (iconIdx: IconAddr) => void;
}


export const MarkerIconSearchComponent: React.FC<Props> = (props: Props) => {
  const {
    onSelectIcon
  } = props;

  const t = useTranslations();
  const docState = useDocState();
  const IconsRepo = useIconsRepo();

  const {
    setEditorMarkerSession,
  } = useEditorStatesSetters();
  
  const iconSearchName = useRecoilValue(UIState_EditorMarkerSession.iconSearchName);

  const setIconName = (iconName: string) => {
    docState.editorMarkerSession.updateEditor({iconSearchName: iconName});
    setEditorMarkerSession();
  }

  const handleSelectMarker = (iconIdx: IconAddr) => {
    onSelectIcon(iconIdx);
  }

  const renderIcon = (iconIdx: IconAddr) => {
    const marker = IconsRepo.getIcon(iconIdx);
    const Icon = marker.Icon;

    return (
      <Button
        key={`${iconIdx.column}-${iconIdx.row}`} 
        data-test={`markers-icons-search-icon-${iconIdx.column}-${iconIdx.row}`}
        onClick={() => { handleSelectMarker(iconIdx); }}
      >
        <Icon /> 
      </Button>
    );
  }

  const renderIcons = () => {
    const filteredIcons = IconsRepo.getIcons(iconSearchName);
    const iconsComps = filteredIcons.map((iconIdx, idx) => renderIcon(iconIdx));

    if ( iconsComps.length === 0) {
      return null;
    };

    return (
      <Panel>
        <IconsScroller>
          <IconsWrapper>
            <IconContext.Provider 
              value={{ 
                size: '2em', 
                style: { 
                  margin: '0.5em' 
                }
              }}
            >
              { iconsComps }
            </IconContext.Provider>
          </IconsWrapper>
        </IconsScroller>
      </Panel>
    );
  }

  const handleInputChange = useThrottledCallback((iconName: string) => {
    setIconName(iconName);
  }, INPUT_CHANGE_THROTTLED_DELAY);


  return (
    <MainWrapper>
      <InputWrapper>
        <InputComponent
          Input={Input}
          title={t("editor marker, icon search")}
          placeholder={"examples: info, stop, box"}
          type="text"
          value={iconSearchName}
          onValueChange={handleInputChange}
          dataTest={'editor-marker-input-icon-name'}
        />
      </InputWrapper>
      { renderIcons() }
    </MainWrapper>
  );
};
