import React from 'react';
import { useRecoilValue } from 'recoil';

import WindowManagedComponent from 'lego-v2/window-managed';

import { Position } from 'app/arch/types';
import { Size } from 'app/arch/types';
import { EditorMarkerSessionDefaults } from 'app/arch/editor-instruction/document/states/sessions/editor-marker-session';

import { useDocState } from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { UIState_EditorMarkerSession } from '../../states';
 

interface Props {
  children: React.ReactNode;
}


export const EditorMarkerWindowComponent = React.forwardRef((
  props: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  const { 
    children 
  } = props;

  const t = useTranslations();
  const document = useDocState();
  const visible  = useRecoilValue(UIState_EditorMarkerSession.windowVisible);

  const {
    setEditorMarkerSession,
  } = useEditorStatesSetters();

  
  //----------------
  // No need to read it from 'dynamic' recoil
  // as this component gets removed from DOM on close
  const editorMarkerSession = document.editorMarkerSession;
  const windowSize = editorMarkerSession.getWindow().size;
  const windowPosition = editorMarkerSession.getWindow().position;
  const windowMaximized = editorMarkerSession.getWindow().maximized;
  const windowSizeMin = EditorMarkerSessionDefaults.getWindowSizeMin();

  const handleWindowSize = (size: Size) => {
    // console.log(`Size ${size[0]}  ${size[1]}`);
    editorMarkerSession.updateWindow({size});
  }

  const handleWindowPosition = (position: Position) => {
    // console.log(`Position ${position[0]}  ${position[1]}`);
    editorMarkerSession.updateWindow({position});
  }

  const handleWindowMaximized = (maximized: boolean) => {
    editorMarkerSession.updateWindow({maximized});
  }

  const handleWindowUpdateDone = () => {
    setEditorMarkerSession();
  }

  const handleWindowClose = () => {
    document.editorMarkerSession.updateWindow({visible: false});
    setEditorMarkerSession();
  }


  return (
    <WindowManagedComponent
      ref={ref}

      dataTest={'editor-marker__window'}
      title={t('marker editor')}
      visible={visible}

      windowSize={windowSize}
      windowSizeMin={windowSizeMin}
      windowPosition={windowPosition}
      windowMaximized={windowMaximized}

      onWindowSize={handleWindowSize}
      onWindowPosition={handleWindowPosition}
      onWindowMaximized={handleWindowMaximized}

      onWindowUpdateDone={handleWindowUpdateDone}
      onWindowClose={handleWindowClose}
    >
      { children }
    </WindowManagedComponent>
  );
});
