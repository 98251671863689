import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSectionSelectedSet from '../use-section-selected-set';
import useSectionDelete      from '../use-section-delete';
import useSectionPropsPanel  from '../use-section-props-panel';


const useSectionMenu = () => {
  const document = useDocState();

  const {
    setContent
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();

  const showSectionPropsPanel = useSectionPropsPanel();
  const deleteSectionHook = useSectionDelete();
  const setSectionSelected = useSectionSelectedSet();

  const addSectionAbove = (sectionAddr: ContentTypes.SectionAddr, event: React.PointerEvent) => {
    const newSectionAddr = document.content.addSectionAbove(sectionAddr);
    document.saveUndo();
    setContent();

    setSectionSelected({sectionAddr: newSectionAddr});
    hideMenu();
  }

  const addSectionBelow = (sectionAddr: ContentTypes.SectionAddr, event: React.PointerEvent) => {
    const newSectionAddr = document.content.addSectionBelow(sectionAddr);
    document.saveUndo();
    setContent();

    setSectionSelected({sectionAddr: newSectionAddr});
    hideMenu();
  }

  const deleteSection = (sectionAddr: ContentTypes.SectionAddr) => {
    deleteSectionHook(sectionAddr);
    hideMenu();
  }
  
  const showSectionProperties = (sectionAddr: ContentTypes.SectionAddr) => {
    showSectionPropsPanel();
    hideMenu();
  }
  
  return {
    addSectionAbove,
    addSectionBelow,
    showSectionProperties,
    deleteSection,
  }
}


export default useSectionMenu;