import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import InputComponent from 'lego/components/input';

import { ToastLevel } from 'app/arch/app/info/toasts/types';
import { ReposTypes } from 'app/arch/app/states/repos';

import useUsername from 'app/ui-v2/app/hooks/use-user-name';
import useToastAdd from 'app/ui-v2/app/__modules/info/components/toasts/hooks/use-toast-add';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useRepoNameUpdate } from 'app/ui-v2/app/hooks/use-repo-name-update';

import { MainWrapper } from './styles';
import { RepoName }    from './styles';



interface Props {
  repoPack: ReposTypes.RepoPack;
}


export const RepoNameComponent: React.FC<Props> = (props: Props) => {
  const { 
    repoPack
  } = props;

  const t = useTranslations();
  const addToast = useToastAdd();

  const [repoName, setRepoName] = useState("");
  const [editing, setEditing]   = useState(false);
  const username = useUsername();

  const members = repoPack.members;
  const member = members.find((member) => member.username === username) !;

  const updateRepoName = useRepoNameUpdate();

  useEffect(() => {
    setRepoName(repoPack.repo.name);
  }, [repoPack]);

  const handleRepoNameChange = (repoName: string) => {
    setRepoName(repoName);
  }

  const hasWriteAccess = () => {
    return [
      ReposTypes.MemberAccess.OWNER,
      ReposTypes.MemberAccess.ADMIN,
    ].includes(member.access);
  }

  const handleEdit = () => {
    if ( ! hasWriteAccess() ) {
      return;
    }
    setEditing(true);
  }

  const handleInputBlur = () => {
    commitEdit();
  }

  const handleInputCancel = () => {
    setEditing(false);
    commitCancel();
  }
  
  const commitEdit = () => {
    const repoName_ = repoName.trim();

    if ( repoName_.length < 3 ) {
      commitCancel();
      setEditing(false);

      addToast({
        text: t('repository name, invalid name'),
        level: ToastLevel.ERROR
      });
      return;
    }

    if (repoName_ !== repoName) {
      setRepoName(repoName_);
    }

    updateRepoName({
      repoId: repoPack.repo.id,
      name: repoName_,
      onError: () => {
        commitCancel();
      }
    });
    setEditing(false);
  }
  
  const commitCancel = () => {
    setRepoName(repoPack.repo.name);
  }

  return (
    <MainWrapper
      $editable={hasWriteAccess()}
      onClick={handleEdit}
    >
    {
      editing &&
      <InputComponent 
        value={repoName}
        onValueChange={handleRepoNameChange}
        blurOnCommit={true}
        focusOnLoad={true}
        onBlur={handleInputBlur}
        onCancel={handleInputCancel}
      />
    }

    {
      ! editing && 
      <RepoName>
      { repoName }
      </RepoName>
    }
    </MainWrapper>
  );
}
  
