import React from 'react';
import { useState } from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import InputComponent from '../../hls/input';

import { Icon }            from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDocState }     from 'app/ui/contexts/document';

import useContextElement           from '../../hooks/use-context-element';
import useColumnsAutoAdjust        from '../../views/view-content/hooks/doc-content/columns/use-columns-auto-adjust';
import ElementCreatePanelComponent from '../../views/shared/components/element-create-panel';

import TitledElementComponent  from '../titled-element';
import ColumnItemTypeComponent from './column-item-type';

import { TypesList } from './styles';
import { Input } from './styles';


interface Props {
  columnAddr?: ContentTypes.ColumnAddr;
  onDone?: (columnAddr: ContentTypes.ColumnAddr) => void;
}


export const ColumnCreatePanelComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr: clickedColumnAddr,
    onDone,
  } = props;

  const t = useTranslations();
  const document = useDocState();

  const [columnName, setColumnName] = useState("");
  const [columnType, setColumnType] = useState(ContentTypes.ColumnType.TEXT);
  const columnsAutoAdjust = useColumnsAutoAdjust();

  const {
    hideElement
  } = useContextElement();

  const createColumn = () => {
    const newColumnAddr = (
      clickedColumnAddr !== undefined ?
      document.content.addColumnAfter(
        clickedColumnAddr,
        columnType,
        { name : columnName }
      ) :
      document.content.addColumn(
        columnType,
        { name : columnName }
      )
    );

    columnsAutoAdjust({ debounced: false });
    document.saveUndo();

    onDone?.(newColumnAddr);
    hideElement();
  }


  const renderItem = (itemDef: any) => (
    <ColumnItemTypeComponent
      Icon={itemDef.Icon}
      text={itemDef.text}
      onClick={itemDef.onClick}
      selected={itemDef.selected}
    />
  );

  const itemText = {
    Icon: Icon.Text,
    text: t("column type, text"),
    onClick: () => setColumnType(ContentTypes.ColumnType.TEXT),
    selected: columnType === ContentTypes.ColumnType.TEXT,
  };

  const itemImages = {
    Icon: Icon.Image,
    text: t("column type, images"),
    onClick: () => setColumnType(ContentTypes.ColumnType.IMAGES),
    selected: columnType === ContentTypes.ColumnType.IMAGES,
  };

  const itemMarkers = {
    Icon: Icon.Marker.Toolbar,
    text: t("column type, markers"),
    onClick: () => setColumnType(ContentTypes.ColumnType.MARKERS),
    selected: columnType === ContentTypes.ColumnType.MARKERS,
  };

  const handleCreate = () => {
    createColumn();
  }

  const handleCancel = () => {
    hideElement();
  }

  const handleInput = (value: string) => {
    setColumnName(value);
  }

  return (
    <ElementCreatePanelComponent
      onCreate={handleCreate}
      onCancel={handleCancel}
    >
      <TitledElementComponent
        title={t("column name")}
      >
        <InputComponent
          Input={Input}
          value={columnName}
          focusOnLoad={true}
          onValueChange={handleInput}
        />
      </TitledElementComponent>
      
      <TitledElementComponent
        title={t("column type")}
      >
        <TypesList>
          { renderItem(itemText) }
          { renderItem(itemImages) }
          { renderItem(itemMarkers) }
        </TypesList>
      </TitledElementComponent>

    </ElementCreatePanelComponent>
  );
}
  
