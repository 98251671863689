import { MenuItemType } from 'lego-v2/menu';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import Icon from 'app/ui/icons/icons';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import useFunctions from './__use-functions';


const useItems = (columnAddr: Types.ColumnAddr) => {
  const t = useTranslations();
  const fns = useFunctions(columnAddr);

  const getColumnAdd = (columnAddr: Types.ColumnAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('column, add'),
      iconComponent: Icon.Create,
      onClick: (event: React.PointerEvent) => {
        setTimeout(() => {
          fns.addColumn(columnAddr, event);
          event.stopPropagation();
          event.preventDefault();
        })
      }
    }
  });

  const getProperties = () => ({
    type: MenuItemType.SUB_MENU,
    data: {
      title: t('properties'),
      iconComponent: Icon.Settings,
      onClick: () => {
        // Nothing to do here
      },
      sections: [
        {
          items: [
            getColumnShowProps(),
            getTableShowProps(),
          ]
        }
      ]
    }
  });

  const getColumnShowProps = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('column'),
      iconComponent: Icon.Column,
      onClick: (event: React.PointerEvent) => fns.showColumnProperties(),
    }
  });

  const getTableShowProps = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('table'),
      iconComponent: Icon.Table,
      onClick: (event: React.PointerEvent) => fns.showTableProperties(),
    }
  });

  const getColumnDelete = (columnAddr: Types.ColumnAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('delete'),
      variant: 'delete',
      iconComponent: Icon.Delete,
      onClick: (event: React.PointerEvent) => fns.deleteColumn(columnAddr, event),
    }
  });

  
  return {
    getColumnAdd,
    getProperties,
    getColumnDelete,
  }
}


export default useItems;