import React from 'react';
import { InputProps } from 'lego/components/input';
import InputComponentBase from 'lego/components/input';
import useKeyBindingBarrier from 'lego-hooks/use-key-binding-barrier';


interface Props extends InputProps {
}


export const InputComponent: React.FC<Props> = (props: Props) => {
  const checkKeyBarrier = useKeyBindingBarrier();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // I'm not sure if anything is using props.onKeyDown
    props.onKeyDown?.(event);
    checkKeyBarrier(event);
  }

  return (
    <InputComponentBase
      {...props} 
      onKeyDown={handleKeyDown}
    />
  );
}

