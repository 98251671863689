import { UploadSource } from 'app/arch/types/types-v2';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import useImageEdit from 'app/ui-v2/editor-image/hooks/image/use-image-edit';
import useImageDuplicate from 'app/ui-v2/editor-image/hooks/image/use-image-duplicate';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";

import useCellImageUpload from '../use-cell-image-upload';
import useCellImagesImageRemove from '../use-cell-images-image-remove';


const useFunctions = () => {
  const { hideMenu } = useContextMenu();
  const editImage__ = useImageEdit();
  const duplicateImage__ = useImageDuplicate();
  const uploadCellImage = useCellImageUpload();
  const removeImage__ = useCellImagesImageRemove();

  const uploadImageFromDisk = (cellAddr: ContentTypes.CellAddr) => {
    const source   = UploadSource.DISK;
    uploadCellImage({ cellAddr, source });
    hideMenu();
  }
  
  const uploadImageFromCamera = (cellAddr: ContentTypes.CellAddr) => {
    const source   = UploadSource.CAMERA_BACK;
    uploadCellImage({cellAddr, source});
    hideMenu();
  }

  const editImage = (imageAddr: ContentTypes.ImageAddr) => {
    editImage__(imageAddr);
    hideMenu();
  }

  const duplicateImage = (imageAddr: ContentTypes.ImageAddr) => {
    duplicateImage__(imageAddr);
    hideMenu();
  }

  const removeImage = (imageAddr: ContentTypes.ImageAddr) => {
    removeImage__(imageAddr);
    hideMenu();
  }

  return {
    uploadImageFromDisk,
    uploadImageFromCamera,
    editImage,
    duplicateImage,
    removeImage,
  }
}


export default useFunctions;