import { atom     } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';
import { AppState as State } from 'app/arch/app/states/app';


export const state = atom<State.State>({
  key: "appStates",
  default: State.createInitialState()
});


export const isLogged = selector({
  key: `appStates_isLogged`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getIsLogged(stateObj);
  }
});


export const isMobile = selector({
  key: `appStates_isMobile`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getIsMobile(stateObj);
  }
});

