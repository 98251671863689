
import Icon from 'app/ui/icons/icons';
import { ViewTypes } from 'app/arch/home-page-user/states/view';
import { NavbarTypes as NavbarStateTypes } from "app/arch/home-page-user/states/navbar";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { NavbarTypes } from 'app/ui-v2/home-page/hls/navbar2';

import useStates    from './use-states';
import useFunctions from './use-functions';


const useConfigMenu = () => {

  const t = useTranslations();
  const states = useStates();
  const fns = useFunctions();

  const getConfig = () => {

    const config: NavbarTypes.Config  = {
      items: [
        
        /**
         * Workspace
         */

        {
          text: t("homepage user, topmenu, workspace"),
          dataTest: 'homepage-user__topmenu__workspace',
          selected: [
            NavbarStateTypes.NavbarItem.DOCUMENTS, 
            NavbarStateTypes.NavbarItem.REPOSITORIES
          ].includes(states.navbarItemSelected),

          panel: {
            sections: [
              {

                items: [
                  {
                    text: t("homepage user, topmenu, workspace, documents"),
                    dataTest: 'homepage-user__topmenu__workspace__documents',
                    icon: Icon.Documents,
                    onClick: (event: React.MouseEvent | React.TouchEvent) => {
                      fns.handleSelect({
                        view: ViewTypes.ViewItem.DOCUMENTS,
                        navbarItem: NavbarStateTypes.NavbarItem.DOCUMENTS,
                      });
                    },
                  },
                  {
                    text: t("homepage user, topmenu, workspace, repositories"),
                    dataTest: 'homepage-user__topmenu__workspace__repositories',
                    icon: Icon.Members,
                    onClick: (event: React.MouseEvent | React.TouchEvent) => { 
                      fns.handleSelect({
                        view: ViewTypes.ViewItem.REPOSITORIES,
                        navbarItem: NavbarStateTypes.NavbarItem.REPOSITORIES,
                      }); 
                    },
                  },
                ]
              },
            ]
          }
        },
  

        /**
         * Pricing
         */

        {
          text: t("homepage user, topmenu, pricing"),
          dataTest: 'homepage-user__topmenu__pricing',
          selected: [
            NavbarStateTypes.NavbarItem.PRICING, 
          ].includes(states.navbarItemSelected),
          onClick: (event: React.MouseEvent | React.TouchEvent) => {
            fns.handleSelect({
              view: ViewTypes.ViewItem.PRICING,
              navbarItem: NavbarStateTypes.NavbarItem.PRICING,
            }); 
          },

          // panel: {
          //   sections: [
          //     {
          //       items: [
          //         {
          //           text: t("homepage user, topmenu, pricing, plans & pricing"),
          //           dataTest: 'homepage-user__topmenu__pricing__plans-and-pricing',
          //           icon: Icon.Pricing,
          //           onClick: () => { 
          //             fns.handleSelect({
          //               view: ViewTypes.ViewItem.PRICING,
          //               navbarItem: NavbarStateTypes.NavbarItem.PRICING,
          //             }); 
          //           },
          //         },
          //       ]
          //     },
          //   ]
          // }
        },
        
        /**
         * Legal
         */

        {
          text: t("homepage user, topmenu, legal"),
          dataTest: 'homepage-user__topmenu__legal',
          selected: [
            NavbarStateTypes.NavbarItem.LEGAL_TERMS_OF_SERVICE, 
            NavbarStateTypes.NavbarItem.LEGAL_PRIVACY_POLICY, 
          ].includes(states.navbarItemSelected),
          panel: {
            sections: [
              {
                items: [
                  {
                    text: t("homepage user, topmenu, legal, terms of service"),
                    dataTest: 'homepage-user__topmenu__legal__terms-of-service',
                    icon: Icon.legal.TermsOfService,
                    onClick: (event: React.MouseEvent | React.TouchEvent) => { 
                      fns.handleSelect({
                        view: ViewTypes.ViewItem.LEGAL_TERMS_OF_SERVICE,
                        navbarItem: NavbarStateTypes.NavbarItem.LEGAL_TERMS_OF_SERVICE,
                      }); 
                    },
                  },

                  {
                    text: t("homepage user, topmenu, legal, privacy policy"),
                    dataTest: 'homepage-user__topmenu__legal__privacy-policy',
                    icon: Icon.legal.PrivacyPolicy,
                    onClick: (event: React.MouseEvent | React.TouchEvent) => { 
                      fns.handleSelect({
                        view: ViewTypes.ViewItem.LEGAL_PRIVACY_POLICY,
                        navbarItem: NavbarStateTypes.NavbarItem.LEGAL_PRIVACY_POLICY,
                      }); 
                    },
                  },

                ]
              }
            ]
          }
        },
      ]
    }

    return config;
  }
  
  const hook = () => {
    return getConfig();
  }

  return hook;
};


export default useConfigMenu;