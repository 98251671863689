import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useCellImagesImagesResetEdited = () => {
  const docState = useDocState();

  const {
    setEditorImageSession,
  } = useEditorStatesSetters();

  const resetImageEdited = (imagesAddrs_: ContentTypes.ImageAddr[] | ContentTypes.ImageAddr) => {
    const imageAddrEdited = docState.editorImageSession.getImageEdited();
    if ( imageAddrEdited === null ) {
      return;
    }

    const isArray = Array.isArray(imagesAddrs_); 
    const imagesAddrs = (
      isArray ? 
      imagesAddrs_ :
      [imagesAddrs_]
    );

    const isImageEdited = imagesAddrs.some(imageAddr => ContentTools.compareImageAddr(imageAddr, imageAddrEdited));
    if ( isImageEdited ) {
      docState.editorImageSession.setImageEdited(null);
      docState.editorImageSession.updateWindow({visible: false});
      setEditorImageSession();
    }
  }

  return resetImageEdited;
}


export default useCellImagesImagesResetEdited;