import React, { useState } from 'react';

import InputComponent from 'lego/components/input';
import Icon from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { IconWrapper } from './styles';
import { InputRowWrapper } from './styles';
import { InputWrapper } from './styles';
import { IconAnchor } from './styles';
import { Input } from './styles';


interface Props {
  onSearch: (searchPattern: string) => void;
  onCancel: () => void;
}


export const SearchInputComponent: React.FC<Props> = (props: Props) => {
  const {
    onSearch,
    onCancel,
  } = props;

  const t = useTranslations();
  const IconSearch = Icon.Search;
  const [searchPattern, setSearchPattern] = useState("");

  const handleSearch = (searchPattern_: string) => {
    setSearchPattern(searchPattern_);
    onSearch(searchPattern_);
  }

  const handleCancel = () => {
    setSearchPattern("");
    onCancel();
  }

  return (
    <InputRowWrapper>
    
      <InputWrapper>
        <InputComponent
          value={searchPattern}
          onValueChange={handleSearch}
          onCancel={handleCancel}
          placeholder={t("view documents, search, placeholder")}
          Input={Input}
          blurOnCommit={true}
          blurOnCancel={true}
        />
      </InputWrapper>

      <IconAnchor>
        <IconWrapper>
          <IconSearch />
        </IconWrapper>
      </IconAnchor>

    </InputRowWrapper>
  );
}
