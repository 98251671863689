import { useSetRecoilState } from "recoil";

import { UIAppStates } from "../states";
import useAppState from "./use-app-state";


const useAppStateStateSetters = () => {
  const appState = useAppState();

  const __setAppState = useSetRecoilState(UIAppStates.state);
  const setAppState = () => __setAppState(appState.raw);

  return {
    setAppState,
  }
}

export default useAppStateStateSetters;