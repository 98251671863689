import { useDocState } from 'app/ui/contexts/document';
import useDocumentImageDelete from 'app/ui-v2/editor-instruction/hooks/use-document-image-delete';
import useDocumentImagesDelete from 'app/ui-v2/editor-instruction/hooks/use-document-images-delete';


const useFunctions = () => {
  const docState = useDocState();

  // TODO: These two functions should be united
  const deleteDocumentImage  = useDocumentImageDelete();
  const deleteDocumentImages = useDocumentImagesDelete();

  const deleteImages = (event: KeyboardEvent) => {
    const imagesSelected = docState.galleryImageSelected.getSelected();
    const imagesKeys = Object.keys(imagesSelected);
    if ( ! imagesKeys.length ) {
      return false;
    }

    const imagesIds = imagesKeys.map(imageId => +imageId);

    const deleteFn = (
      imagesIds.length === 1 ?
      deleteDocumentImage :
      deleteDocumentImages
    );

    deleteFn(imagesIds);

    return true;
  }

  return {
    deleteImages,
  }
}

export default useFunctions;