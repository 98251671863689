import React from 'react';

import PanelTitledComponent from 'app-views/components/panel-titled';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useRepos from 'app/ui-v2/app/hooks/use-repos';
import useUsername from 'app/ui-v2/app/hooks/use-user-name';

import RepoNameComponent from './repo-name';

import { Row }       from "./styles";
import { Value }     from "./styles";
import { Column }    from "./styles";
import { ValueName } from "./styles";


interface Props {
  repoId: number;
}

export const RepoInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    repoId
  } = props;

  const t = useTranslations();
  const username = useUsername();

  const repos = useRepos();
  const repoPack = repos.getRepoPack(repoId);
  const member = repos.getMember(repoId, username);

  return (
    <PanelTitledComponent title={t('repository info')} >
      <Column>
        <Row>
          <RepoNameComponent repoPack={repoPack} />
          <ValueName>
            { t("repository name") }
          </ValueName>
        </Row>

        <Row>
          <Value>
            { member.access }
          </Value>
          <ValueName>
            { t("repository user access") }
          </ValueName>
        </Row>
      </Column>

    </PanelTitledComponent>
  );
}
