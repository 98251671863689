import React from 'react';
import RedirectGoUrlComponent from '../parts/redirect-go-url';


interface Props {
}


export const RedirectExpoComponent: React.FC<Props> = (props: Props) => {
  const message = 'Maintenance Expo Poland';
  const url = 'https://www4.pwe-expoplanner.com/sklep/index.php?id_category=1139&controller=category&id_lang=2';

  return (
    <RedirectGoUrlComponent
      message={message}
      url={url}
    />
  );
}

