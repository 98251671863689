import styled from "styled-components";
import InputBase from "lego/styles/input";


export const Input = styled(InputBase)`
  // TODO
  // Othewise Input gets to big and pushs 
  // button out of the screen.
  width: 0px;
  flex-grow: 1;
`;
