const useKeyBindingBarrier = () => {

  const checkKeyBarrier = (event: React.KeyboardEvent) => {
    // TODO: FIXME (keybinding)
    //
    // This is really a hack. Should be fixed in architecture changes.
    //
    // It has to be here as otherwise key down event
    // will be caught by underling useKeybindings, and then:
    //
    // - backspace, delete, escape key will be ignored here =>
    //   it will be impossible to delete any char in input
    //
    // - but you can't ignore everything as then combination from top menu
    //   would not work (combinations like 'alt+s')

    const ignore = [
      'Delete',
      'Backspace',
      'Escape',
    ].includes(event.key);

    if ( ignore ) {
      event.stopPropagation();
    }
  }


  return checkKeyBarrier;
}


export default useKeyBindingBarrier;