import styled from 'styled-components';
import { Input as InputBase } from 'lego/components/input-raw/styles';


export const InputRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  /* background-color: blue; */
`;

export const InputWrapper = styled.div`
  flex: 1 1 auto;
`;

export const IconAnchor = styled.div`
  right: 0px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 8px;

  flex: 0 0 32px;
  /* background-color: yellow; */

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${props => props.theme.defs.colors.color.primary};
  opacity: ${props => props.theme.defs.opacity.fade};
  pointer-events: none;
`;


export const Input = styled(InputBase)`
  border-radius: 0px;
  border-left-width: 0px;
  border-right-width: 0px;

  &:focus-visible {
    position: relative;
    left: 2px;
    max-width: calc(100% - 3px);
    border-color: transparent;
  }
`;