import styled from 'styled-components';
import Scrollbar from 'lego/styles/scrollbar';


interface ContentCliperProps {
  $isResizing: boolean;
}

export const SizeWatch = styled.div`
  width: 100%;
  height: 100%;
`;


export const Scroller = styled(Scrollbar).attrs<ContentCliperProps>(props =>({
  style: { 
    overflow: `${(props.$isResizing) ? 'hidden' : 'auto'}` 
  }
}))<ContentCliperProps>`
  width: 100%;
  height: 100%;
`;
