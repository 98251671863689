import { useRef } from 'react';
import { useEffect } from 'react';

import { useProgressModal } from 'lego-v2/progress-modal';

import { useMutation } from 'app/arch/backend/use-mutation';
import { mutation as mutationDef } from 'app/arch/backend';
import { useDocState } from 'app/ui/contexts/document';
import { MsgDuration } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useDialogYesNo } from 'app/ui/components/editor-instruction/dialogs/dialog-yes-no/use-dialog-yes-no';

import useEditorStatesSetters from './use-editor-states-setters';

type ImagesIds = number[];


export interface DialogProps {
  title: string;
  message: string;
}

export interface MsgboxProps {
  startMsg: string;
  stopMsg: string;
}


const useDocumentImageDeleteBase = ({
  title,
  message
}: DialogProps, {
  startMsg,
  stopMsg,
}: MsgboxProps) => {
  const imagesIdsRef = useRef<null | ImagesIds>(null);
  const docState = useDocState();

  const {
    setContent,
    setRepoImages,
    setGalleryImagesSelected,
    setEditorImageSession,
  } = useEditorStatesSetters();

  const { 
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();


  //---------------
  // Yes/No dialog
  //
  const {
    show: showDialogYesNo,
  } = useDialogYesNo();


  //-------------------------
  // Delete instruction
  //
  const { 
    mutation: mutation,
    data: response,
    error: mutationError,
  } = useMutation(
    mutationDef.deleteInstructionImages,
    {
      onStart: { msg: startMsg },
      onEnd: {
        msg: stopMsg,
        duration: MsgDuration.MIDDLE
      }
    }
  );


  useEffect(() => {
    if ( ! response ) {
      return;
    }

    const data = response.instructionImagesDelete;
    if (data.ok) {
      const imagesIds = data.imagesIds as number[];
      imagesIds.map(imageId => {
        docState.docImage_delete(imageId);
      });

      docState.saveUndo();

      setContent();
      setRepoImages();

      setEditorImageSession();

      docState.galleryImageSelected.reset();
      setGalleryImagesSelected();
    }
    else {
      console.warn(`Image has not been delete, unknown error`);
    }

    hideProgressModal();
    imagesIdsRef.current = null;
  }, [response]);

  useEffect(() => {
    if ( ! mutationError ) {
      return;
    }
    
  }, [mutationError]);


  const deleteImages = (imagesIds: ImagesIds) => {
    const noAutoFocus = true;
    imagesIdsRef.current = imagesIds;

    const onNoHandler  = () => {};
    const onYesHandler = () => {
      showProgressModal();
      mutation({ 
        variables: { ids: imagesIds } 
      });
    }

    showDialogYesNo({
      title, 
      message, 
      noAutoFocus, 
      onYesHandler, 
      onNoHandler 
    });
  }


  return deleteImages;
}


export default useDocumentImageDeleteBase;
