import React from 'react';
import { useRef }     from 'react';
import { useEffect }  from 'react';
import { useState }   from 'react';

import { Input as InputDefault } from './styles';


export interface Props {
  value?: string;
  type?: string;

  focusOnLoad?: boolean;
  blurOnCommit?: boolean;
  blurOnCancel?: boolean;
  
  onFocus?: (event?: any) => void;
  onBlur?: (event?: any) => void;
  onValueChange?: (value: string) => void;
  onCommit?: (value: string) => void;
  onCancel?: () => void;
  onKeyDown?: (event: any) => void;

  disabled?: boolean;
  placeholder?: string;
  Input?: React.ElementType;

  dataTest?: string;
};


export const InputRawComponent: React.FC<Props> = (props: Props) => {
  const {
    value: initValue,
    onFocus,
    onBlur,
    onCancel,
    onCommit,
    onKeyDown,
    onValueChange,
    focusOnLoad,
    blurOnCommit,
    blurOnCancel,
    disabled,
    placeholder,
    dataTest
  } = props;

  const [isFocused, setFocused] = useState(false);
  const [value, setValue] = useState(initValue || '');
  const type = (props.type || 'text');

  const Input = props.Input || InputDefault;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && focusOnLoad) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    setValue(initValue ?? '');
  }, [initValue]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    onKeyDown && onKeyDown(event);

    if (blurOnCommit && event.key === 'Enter') {
      inputRef.current?.blur();
      event.stopPropagation();
      event.preventDefault();
    }

    if (blurOnCancel && event.key === 'Escape') {
      inputRef.current?.blur();
      event.stopPropagation();
      event.preventDefault();
    }


    if (event.key === "Enter") {
      onCommit?.(value);
    }

    else if (event.key === "Escape") {
      onCancel?.();
    } 
  }

  const handleChange = (event: any) => {
    setValue(event.target.value);
    onValueChange?.(event.target.value);
  }

  const handleFocus = (event: any) => {
    onFocus?.();
    setFocused(true);
  }

  const handleBlur = (event: any) => {
    onBlur?.(event);
    setFocused(false);
  }


  return (
    <Input
      disabled={disabled}
      ref={inputRef}
      type={type}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      focused={isFocused}

      data-test={dataTest}
    />
  );
}

