import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import * as Types from '../types';
import SortMarkComponent from './sort-mark';
import SearchInputComponent from './search-input';
import { MainColumn } from './styles';
import { PanelHeader } from './styles';
import { SortDate } from './styles';
import { SortTitle } from './styles';


interface Props {
  onSort: (sortType: Types.SortBy) => void;
  sortBy: Types.SortBy;
  sortOrder: Types.SortOrder;

  onSearch: (searchText: string) => void;
  onSearchCancel: () => void;
}


export const InstructionListHeaderComponent: React.FC<Props> = (props: Props) => {
  const {
    onSort,
    sortBy,
    sortOrder,

    onSearch,
    onSearchCancel,
  } = props;

  const t = useTranslations();

  const handleSortTitle = (event: React.MouseEvent) => {
    onSort(Types.SortBy.TITLE);
  }

  const handleSortDate = (event: React.MouseEvent) => {
    onSort(Types.SortBy.DATE_UPDATED);
  }

  return (
    <MainColumn>
      <PanelHeader>
        <SortTitle 
          onClick={handleSortTitle}
        >
          { t("docs header, name") }
          {
            sortBy === Types.SortBy.TITLE &&
            <SortMarkComponent sortOrder={sortOrder} />
          }

        </SortTitle>
        <SortDate
          onClick={handleSortDate}
        >
          { t("docs header, date update") }
          {
            sortBy === Types.SortBy.DATE_UPDATED &&
            <SortMarkComponent sortOrder={sortOrder} />
          }
        </SortDate>
      </PanelHeader>

      <SearchInputComponent 
        onSearch={onSearch}
        onCancel={onSearchCancel}
      />
    </MainColumn>
  );
}
