import { useEffect, useRef } from "react";
import { mutation as mutationDefs } from 'app/arch/backend';
import { useMutation } from 'app/arch/backend/use-mutation';
import { useTranslations } from "app/ui/hooks/app/use-translation";


interface FnProps {
  documentId: number,
  revision: number,
  recipient: string,

  onDone?: () => void;
  onError?: (error: string) => void;
}

const useDocumentReleaseSend = () => {
  const t = useTranslations();

  const { 
    mutation,
    data: response,
    error,
  } = useMutation(mutationDefs.sendInstructionRelease, {
    onStart: { msg: t("doc release send, msgbox, sending") },
    onEnd:   { msg: t("doc release send, msgbox, sent") },
  });

  const hookPropsRef = useRef<FnProps | null>(null);

  useEffect(() => {
    if (! response) {
      return;
    }
    const data = response.instructionReleaseSend;
    const hookProps = getHookProps();

    if ( ! data.ok ) {
      console.warn(`Can't send email`);
      hookProps.onError?.("Data.ok = false");
      resetRef();
      return;
    }

    hookProps.onDone?.();
    resetRef();
  }, [response]);

  useEffect(() => {
    if (! error) {
      return;
    }

    const hookProps = getHookProps();
    hookProps.onError?.(error.message);
    resetRef();
  }, [error]);

  const resetRef = () => {
    hookPropsRef.current = null;
  }

  const getHookProps = () => {
    const hookProps = hookPropsRef.current;

    if ( ! hookProps ) {
      const msg = `HookProps are null`;
      throw new Error(msg);
    }

    return hookProps;
  }
  const sendDocRelease = (props: FnProps) => {
    const {
      documentId,
      revision,
      recipient,
    } = props;

    const instructionId = documentId;
    hookPropsRef.current = props;

    mutation({
      variables: { instructionId, revision, recipient }
    });
  }

  return sendDocRelease;
}

export default useDocumentReleaseSend;