import { ContentTools, ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useCellImagesImagesResetEdited from '../use-cell-images-images-reset-edited';


const useFunctions = () => {
  const docState = useDocState();
  const { hideMenu } = useContextMenu();
  const imagesResetEdited = useCellImagesImagesResetEdited();

  const {
    setContent,
    setContentCellImagesSelected,
  } = useEditorStatesSetters();


  const duplicateImages = () => {
    const selected = docState.contentCellImagesSelected.getSelected();
    const imagesKeys = Object.keys(selected);
    const imagesAddrs = imagesKeys.map(imageKey => ContentTools.imageKeyToAddr(imageKey));

    const newImagesAddrs: ContentTypes.ImageAddr[] = [];

    imagesAddrs.forEach((imageAddr) => {
      const newImageAddr = docState.cellImages_duplicateImage(imageAddr);
      newImagesAddrs.push(newImageAddr);
    });

    docState.contentCellImagesSelected.reset();
    newImagesAddrs.forEach(newImageAddr => docState.contentCellImagesSelected.select(newImageAddr));
    setContentCellImagesSelected();

    docState.saveUndo();
    setContent();
    hideMenu();
  }

  const removeImages = () => {
    const selected = docState.contentCellImagesSelected.getSelected();
    const imagesKeys = Object.keys(selected);
    const imagesAddrs = imagesKeys.map(imageKey => ContentTools.imageKeyToAddr(imageKey));

    imagesAddrs.forEach((imageAddr) => {
      docState.content.cellImages_removeImage(imageAddr);
    });

    docState.contentCellImagesSelected.reset();
    setContentCellImagesSelected();

    docState.saveUndo();
    setContent();

    imagesResetEdited(imagesAddrs);
    hideMenu();
  }

  return {
    duplicateImages,
    removeImages,
  }
}


export default useFunctions;