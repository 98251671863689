import React from 'react';

import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import useItemContextMenu from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-context-menu';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useGalleryMarkersMarkersMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/gallery-markers/use-gallery-markers-markers-menu';
import useGalleryMarkersMarkerMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/gallery-markers/use-gallery-markers-marker-menu';
import { useDocState } from 'app/ui/contexts/document';

import { MainWrapper } from './styles';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;
  
  const docState = useDocState();
  const getConfigMarkers = useGalleryMarkersMarkersMenu();
  const getConfigMarker  = useGalleryMarkersMarkerMenu();
  const resetAllSelected = useAllSelectedReset();

  const {
    setGalleryMarkersSelected,
  } = useEditorStatesSetters();


  type Addr = RepoMarkersTypes.MarkerAddr;

  const handleContextMenu_ = useItemContextMenu<Addr>({
    isSelected: (addr: Addr) => docState.galleryMarkersSelected.isSelected(addr),
    isMultiselected: () => docState.galleryMarkersSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    resetOtherSelected: () => resetAllSelected({skipGalleryMarkersReset: true}),
    select: (addr: Addr) => {
      docState.galleryMarkersSelected.select(addr);
      setGalleryMarkersSelected();
    },
    getConfigItems: () => getConfigMarkers(),
    getConfigItem: (addr:Addr) => getConfigMarker(addr)
  });

  const handleContextMenu = (event: React.MouseEvent) => {
    handleContextMenu_(event, markerAddr);
  }


  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
