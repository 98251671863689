import * as Defaults from './defaults';
import * as Types from './types';


/**
 * State
 */
export type State = Types.AppState;


/**
 * Getters
 */
export const getIsLogged   = (state: State) => state.isLogged;
export const getIsMobile   = (state: State) => state.isMobile;
export const getIsPrintout = (state: State) => state.isPrintout;


/**
 * Initial state
 */
export const createInitialState = (): State => {
  const initState: State = Defaults.getAppState();
  return initState;
}
