import React from 'react';
import { useEffect } from 'react';
import jtl from 'tools/jtl';
import useAppState from 'app/ui-v2/app/__modules/app-states/hooks/use-app-state';
import useAppStateStateSetters from 'app/ui-v2/app/__modules/app-states/hooks/use-app-state-state-setters';


interface Props {
}


export const MobileCheckComponent: React.FC<Props> = (props: Props) => {
  const appState = useAppState();
  const { setAppState } = useAppStateStateSetters();

  useEffect(() => {
    const isMobile = jtl.device.isMobile();
    const update = { isMobile };
    appState.updateState(update);
    setAppState();
  }, []);

  return null;
}
