import React from 'react';
import { useRecoilValue } from 'recoil';

import WindowManagedComponent from 'lego-v2/window-managed';

import { Size }     from 'app/arch/types';
import { Position } from 'app/arch/types';
import { EditorImageSessionDefaults } from 'app/arch/editor-instruction/document/states/sessions/editor-image-session';

import { useDocState } from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';

import useSelectedReset from '../../hooks/use-selected-reset';
import useImageViewAutoFit from '../../hooks/image/use-image-view-auto-fit';


interface Props {
  children: React.ReactNode;
}


export const EditorImageWindowComponent = React.forwardRef((
  props: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    children,
  } = props;
  
  const t = useTranslations();
  const document = useDocState();
  const visible  = useRecoilValue(UIState_EditorImageSession.windowVisible);

  const editorImageSession = document.editorImageSession;
  const windowSize         = editorImageSession.getWindowSize();
  const windowPosition     = editorImageSession.getWindowPosition();
  const windowMaximized    = editorImageSession.getWindowMaximized();
  const windowSizeMin      = EditorImageSessionDefaults.getWindowSizeMin();

  const {
    setEditorImageSession
  } = useEditorStatesSetters();

  const resetSelected = useSelectedReset();
  const imageViewAutoFit = useImageViewAutoFit();

  const handleWindowSize = (size: Size) => {
    document.editorImageSession.updateWindow({size});
  }

  const handleWindowPosition = (position: Position) => {
    document.editorImageSession.updateWindow({position});
  }

  const handleWindowMaximized = (maximized: boolean) => {
    document.editorImageSession.updateWindow({maximized});
  }

  const handleWindowClose = () => {
    const imageEdited = document.editorImageSession.getImageEdited();
    if (!  imageEdited ) {
      const msg = `ImageEdited addr is null`;
      console.error(msg);
    }
    else {
      imageViewAutoFit(imageEdited);
    }

    document.editorImageSession.updateWindow({visible: false});
    document.editorImageSession.setImageEdited(null);
    resetSelected({skipStateUpdate: true});
    setEditorImageSession();
  }

  const handleWindowUpdateDone = () => {
    setEditorImageSession();
  }
  
  return (
    <WindowManagedComponent
      ref={ref}

      dataTest={'editor-image__window'}
      title={t("image editor")}
      visible={visible}

      windowSize={windowSize}
      windowSizeMin={windowSizeMin}
      windowPosition={windowPosition}
      windowMaximized={windowMaximized}

      onWindowSize={handleWindowSize}
      onWindowPosition={handleWindowPosition}
      onWindowMaximized={handleWindowMaximized}

      onWindowClose={handleWindowClose}
      onWindowUpdateDone={handleWindowUpdateDone}
    >
      { children }
    </WindowManagedComponent>
  );
});
