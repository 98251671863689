import React from 'react';
import { useRef } from 'react';
import { useRecoilValue } from 'recoil';

import EditorMarkerWindowComponent  from './components/editor-marker-window';
import EditorMarkerContentComponent from './components/editor-marker-content';
import EditorMarkerTopMenuComponent from './components/editor-marker-top-menu';
import EditorMarkerKeybindingsComponent from './components/editor-marker-keybindings';

import { UIState_EditorMarkerSession } from './states';

import { MainColumn }     from './styles';
import { ContentWrapper } from './styles';
import { TopMenuWrapper } from './styles';


interface Props {
}


export const EditorMarkerComponent: React.FC<Props> = (props: Props) => {
  const windowRef = useRef<HTMLDivElement>(null);
  const selectedMarkerAddr = useRecoilValue(UIState_EditorMarkerSession.selectedMarkerAddr);
  

  return (
    <EditorMarkerWindowComponent ref={windowRef}>
      <EditorMarkerKeybindingsComponent windowRef={windowRef}>
        <MainColumn>
          
          <TopMenuWrapper>
            <EditorMarkerTopMenuComponent />
          </TopMenuWrapper>

          {
            selectedMarkerAddr &&
            <ContentWrapper>
              <EditorMarkerContentComponent markerAddr={selectedMarkerAddr} />
            </ContentWrapper>
          }
        </MainColumn>
      </EditorMarkerKeybindingsComponent>
    </EditorMarkerWindowComponent>
  );
}
