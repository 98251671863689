import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useCellImagesImagesResetEdited from '../use-cell-images-images-reset-edited';


const useCellImagesImageRemove = () => {
  const docState = useDocState();
  const {
    setContent,
  } = useEditorStatesSetters();

  const imagesResetEdited = useCellImagesImagesResetEdited();

  const removeImage = (imageAddr: ContentTypes.ImageAddr) => {
    docState.content.cellImages_removeImage(imageAddr);
    docState.saveUndo();
    setContent();

    imagesResetEdited(imageAddr);
  }

  return removeImage;
}


export default useCellImagesImageRemove;