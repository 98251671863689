import React from 'react';

import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import HLC_MarkerDraggerComponent from 'app/ui-v2/editor-instruction/hls/markers/marker-dragger';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr,
    children, 
  } = props;
  
  const setDNDData = useDNDDataSet();
  const { hideMenu } = useContextMenu();
  const resetAllSelected = useSelectedReset();

  const handleDragStart = () => {
    const data = JSON.stringify({markerAddr});
    setDNDData(data);
    resetAllSelected();
  }
  
  const handleDragMoveFirst = () => {
    // There is no need to hide context menu as 
    // there is no context menu to hide
    // hideMenu();
  }

  return (
    <HLC_MarkerDraggerComponent
      markerAddr={markerAddr}
      dndContext={DNDContext.marker.editor}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
    >
      { children }
    </HLC_MarkerDraggerComponent>
  );
}
