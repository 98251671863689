import TextWrap from "lego/styles/text-wrap";
import styled from "styled-components";


export const MainWrapper = styled.div<{$editable: boolean}>`
  cursor: ${props => props.$editable ? 'pointer' : 'default'};
`;

export const RepoName = styled(TextWrap)`
  color: ${props => props.theme.defs.colors.color.primary};
`;