import React from 'react';
import DebugPointComponent from '../debug-point';
import DebugDeviceComponent from '../debug-device';
import { MainWrapper } from './styles';


interface Props {
}


export const DebugModalComponent: React.FC<Props> = (props: Props) => {
  return (
    <MainWrapper>
      {/* <DebugDeviceComponent /> */}
      {/* <DebugPointComponent /> */}
    </MainWrapper>
  );
}
