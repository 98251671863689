import React from "react";
import { useState } from "react";
import { useEffect } from "react";


interface Props {
  elementRef: React.RefObject<HTMLElement>;
  activeInit: boolean;
}

/*
  Set state `isActive` to true, when
  elementRef or any of its children received
  event `PointerDown`.
*/

const useIsElementActive = ({
  elementRef,
  activeInit
}: Props) => {

  const [isActive, setIsActive] = useState(activeInit);
  
  const handlePointerDown = (event: MouseEvent) => {
    const element = elementRef.current;
    if ( ! element ) {
      return;
    }

    const clickedIn = element.contains(event.target as Node);
    setIsActive(clickedIn);
  };

  useEffect(() => {
    document.addEventListener("pointerdown", handlePointerDown, true);
    return () => document.removeEventListener("pointerdown", handlePointerDown, true);
  }, [elementRef.current]);

  return isActive;
};



export default useIsElementActive;