import React from 'react';
import { useEffect } from 'react';
import { useProgressModal } from 'lego-v2/progress-modal';


interface Props {
  message?: string;
  url: string;
}


export const RedirectGoUrlComponent: React.FC<Props> = (props: Props) => {
  const {
    message,
    url,
  } = props;

  const { 
    showProgressModal, 
  } = useProgressModal();

  useEffect(() => {
    setTimeout(() => {
      showProgressModal({message});
      setTimeout(() => { 
        window.location.href = url; 
      });
    });
  }, []);

  return null;
}

