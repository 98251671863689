import React         from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';
import { useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import useKeyBindingBarrier from 'lego-hooks/use-key-binding-barrier';

import { settings } from 'app/configs';
import { UUID }     from 'app/arch/types';
import { useIsPrintout }   from 'app/ui/components/editor-instruction/use-is-printout';
import { useDocState }     from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { UIState_Releaselogs } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useReleaselogIsEditable from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-releaselog-is-editable';
import useReleaselogsState from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-releaselogs-state';

import { TextareaAutosize } from './styles';


const TEXT_DEBOUNCE_DELAY = settings.editorText.updateDebounceDelay;


interface Props {
  releaselogId: UUID;
}


export const ContentDescriptionComponent: React.FC<Props> = (props: Props) => {
  const {
    releaselogId,
  } = props;
  
  const releaseAddr = { releaselogId };

  const releaselogs = useReleaselogsState();
  const descriptionText = useRecoilValue(UIState_Releaselogs.releaselogDescriptionText(releaseAddr));
  
  const isDescriptionVisibile = () => {
    const isEditable = releaselogs.isReleaselogEditable(releaseAddr);
    if ( isEditable ) {
      return true;
    }

    // Not the last releaselog 
    return (
      descriptionText &&
      descriptionText.length > 0
    );
  }


  if ( ! isDescriptionVisibile() ) {
    return null;
  }

  return (
    <ContentDescriptionComponent__ releaselogId={releaselogId} />
  )
}


const ContentDescriptionComponent__: React.FC<Props> = (props: Props) => {
  const {
    releaselogId,
  } = props;
  
  const t = useTranslations();
  const document = useDocState();
  const isPrintout = useIsPrintout();
  const releaselogAddr = { releaselogId };

  const checkKeyBarrier = useKeyBindingBarrier();
  const isEditable = useReleaselogIsEditable(releaselogAddr);
  const descriptionGlobal = useRecoilValue(UIState_Releaselogs.releaselogDescriptionText(releaselogAddr));

  const [descriptionLocal, setDescriptionLocal] = useState<string | null>(descriptionGlobal);
  const [descriptionFocused, setDescriptionFocused] = useState(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const releaselogIdx = document.releaselogs.getReleaselogIdx(releaselogAddr);
  const dataTest = (
    `editor-doc`
    + `__view-releaselog`
    + `__header-description`
    + `__input`
    + `__${releaselogIdx}`
  );


  const {
    setReleaselogs
  } = useEditorStatesSetters();

  useEffect(() => {
    if (descriptionLocal === descriptionGlobal) {
      return;
    }

    setDescriptionLocal(descriptionGlobal);
  }, [descriptionGlobal]);

  const descriptionPlaceholderText = (
    ! isPrintout && 
    ! descriptionFocused ? 
    t("optional summary") :
    ""
  );

  //----------------------
  // Description changes
  //

  const commitGlobalState = useDebouncedCallback(() => {
    document.saveUndo();
    setReleaselogs();
  }, TEXT_DEBOUNCE_DELAY);


  const handleDescriptionChange = (event: any) => {
    const descriptionNewTmp = event.target.value;
    const description = descriptionNewTmp.replace(/^\n+/, '');

    setDescriptionLocal(description);

    document.releaselogs.updateReleaselogInfoDescription(
      releaselogAddr, { text: description }
    );

    commitGlobalState();
  };

  const handleDescriptionFocus = () => {
    setDescriptionFocused(true);
  }

  const handleDescriptionBlur = () => {
    setDescriptionFocused(false);
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // Check key barrier is not really needed here
    // as there is no underlying keybinding for releaselog.
    // Adding here just for consistency.
    checkKeyBarrier(event);
  }


  return (
    <TextareaAutosize
      ref={textareaRef}
      readOnly={! isEditable}
      value={descriptionLocal || ''}
      onInput={handleDescriptionChange}
      onFocus={handleDescriptionFocus}
      onBlur={handleDescriptionBlur}
      placeholder={descriptionPlaceholderText}
      onKeyDown={handleKeyDown}

      $editDisabled={! isEditable}
      data-test={dataTest}
    />
  );
}
