import React from 'react';
import { useEffect } from 'react';
import useIsElementActive from 'lego-hooks/use-is-element-active';
import useKeyBindings from './hooks/use-key-bindings';


interface Props {
  windowRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}


export const EditorImageKeybindingsComponent: React.FC<Props> = (props: Props) => {
  const {
    windowRef,
    children,
  } = props;

  const isActive = useIsElementActive({
    elementRef: windowRef,
    activeInit: true,
  });

  const {
    addBindings,
    removeBindings
  } = useKeyBindings();

  useEffect(() => {
    if ( ! isActive ) {
      return;
    }

    addBindings();

    return () => {
      removeBindings();
    };
  }, [isActive]);


  return children;
}
